import { mapGetters } from 'vuex';

export default defineNuxtComponent({
  name: 'MobileDropdownControllerMixin',
  computed: {
    ...mapGetters('MobileDropdownModalStore', [
      'dropdownOpened',
      'dropdownType',
      'dropdownLabel',
      'dropdownOptions',
      'dropdownCurrentContext',
      'dropdownChecked',
      'optionsWithImage',
      'contextData',
      'customEvent',
    ]),
  },
  methods: {
    registerCustomEvent(event) {
      this.$store.commit('MobileDropdownModalStore/registerCustomEvent', event);
    },
    triggerCustomEvent(eventName, value) {
      const eventToTrigger = this.customEvent(eventName);
      if (eventToTrigger)
        eventToTrigger.callback(value);
    },
    setContextData(data) {
      this.$store.commit('MobileDropdownModalStore/setContextData', data);
    },
    setDropdownOpened(dropdownOpened) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownOpened',
        dropdownOpened,
      );
    },
    setDropdownType(dropdownType) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownType',
        dropdownType,
      );
    },
    setDropdownLabel(dropdownLabel) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownLabel',
        dropdownLabel,
      );
    },
    setDropdownModule(dropdownModule) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownModule',
        dropdownModule,
      );
    },
    setDropdownSecondaryLabel(label) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownSecondaryLabel',
        label,
      );
    },
    setDropdownOptions(dropdownOptions) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownOptions',
        dropdownOptions,
      );
    },
    setDropdownSecondaryOptions(dropdownSecondaryOptions) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownSecondaryOptions',
        dropdownSecondaryOptions,
      );
    },
    setDropdownChecked(checked) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownChecked',
        checked,
      );
    },
    setDropdownTempChecked(temporaryCheck) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownTempChecked',
        temporaryCheck,
      );
    },
    setDropdownSecondaryChecked(secondaryChecked) {
      this.$store.commit(
        'MobileDropdownModalStore/setDropdownSecondaryChecked',
        secondaryChecked,
      );
    },
    setOptionsWithImage(optionsWithImage) {
      this.$store.commit(
        'MobileDropdownModalStore/setOptionsWithImage',
        optionsWithImage,
      );
    },
    setOptionIcon(optionIcon) {
      this.$store.commit('MobileDropdownModalStore/setOptionIcon', optionIcon);
    },
    setOptionIconType(optionIconType) {
      this.$store.commit(
        'MobileDropdownModalStore/setOptionIconType',
        optionIconType,
      );
    },
    setOnOptionChangedCallback(callback) {
      this.$store.commit(
        'MobileDropdownModalStore/setOnOptionChangedCallback',
        callback,
      );
    },
    setOnSecondaryOptionChangedCallback(callback) {
      this.$store.commit(
        'MobileDropdownModalStore/setOnSecondaryOptionChangedCallback',
        callback,
      );
    },
    setOpenModalCurrentContext(label) {
      this.$store.commit(
        'MobileDropdownModalStore/setCurrentDropdownContext',
        label,
      );
    },
    isDropdownOpen(label) {
      return this.dropdownCurrentContext === label && this.dropdownOpened;
    },
  },
});
